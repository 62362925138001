// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
         {
           name: "",
           label: "Inicio",
         },
         {
           name: "pages",
           label: "Productos",
           items: [
             { name: "innerpage/about-us", label: "ValuaBot", name:"valuaBot" },
             { name: "innerpage/service", label: "Control de empeños y compras", name:"loansPurchases" },
             { name: "innerpage/service", label: "Sistema de cobranza", name:"sistemaCobranza" },
             { name: "innerpage/service", label: "Facturación", name:"facturacion" },
             { name: "innerpage/service", label: "Gestor de inventario", name:"inventario" },
             { name: "innerpage/service", label: "Canales de venta", name:"canales" },
           ],
         },
         {
           name: "megamenu",
           label: "Precios",
           name: "precios"
           /* megamenu:true,
           col:12, */
           
           //items: {
            //  image:{
            //   url:menuImage,
            //   col:"5",
            //  },
             /* col_1 : {
              title:"GALLERY STYLE",
              items:[
                {
                 name: "portfolio/grid/portfolio-grid-2",
                 label: "Two Column Grid",
                },
                {
                 name: "portfolio/grid/portfolio-grid-3",
                 label: "Three Column Grid",
                },
                {
                 name: "portfolio/grid/portfolio-grid-4",
                 label: "Four Column Grid",
                },
                {
                 name: "portfolio/grid/portfolio-grid-2-full",
                 label: "Two Column Grid Wide",
                },
                {
                 name: "portfolio/grid/portfolio-grid-3-full",
                 label: "Three Column Grid Wide",
                },
                {
                 name: "portfolio/grid/portfolio-grid-4-full",
                 label: "Four Column Grid Wide",
                },
               ]
             },
             col_2 : {
              title:"GALLERY (NO GAP)",
              items:[
                {
                 name: "portfolio/grid/portfolio-grid-2-no-gap",
                 label: "Two Column Grid",
                },
                {
                 name: "portfolio/grid/portfolio-grid-3-no-gap",
                 label: "Three Column Grid",
                },
                {
                 name: "portfolio/grid/portfolio-grid-4-no-gap",
                 label: "Four Column Grid",
                },
                {
                 name: "portfolio/grid/portfolio-grid-2-no-gap-full",
                 label: "Two Column Grid Wide",
                },
                {
                 name: "portfolio/grid/portfolio-grid-3-no-gap-full",
                 label: "Three Column Grid Wide",
                },
                {
                 name: "portfolio/grid/portfolio-grid-4-no-gap-full",
                 label: "Four Column Grid Wide",
                },
               ]
             },
             col_3 : {
              title:"MASONRY STYLE",
              items:[
                {
                 name: "portfolio/masonry/portfolio-masonary-2",
                 label: "Two Column Masonary",
                },
                {
                 name: "portfolio/masonry/portfolio-masonary-3",
                 label: "Three Column Masonary",
                },
                {
                 name: "portfolio/masonry/portfolio-masonary-4",
                 label: "Four Column Masonary",
                },
                {
                 name: "portfolio/masonry/portfolio-masonary-2-full",
                 label: "Two Column Masonary Wide",
                },
                {
                 name: "portfolio/masonry/portfolio-masonary-3-full",
                 label: "Three Column Masonary Wide",
                },
                {
                 name: "portfolio/masonry/portfolio-masonary-4-full",
                 label: "Four Column Masonary Wide",
                },
               ]
             },
             col_4 : {
              title:"PORTFOLIO DETAILS",
              items:[
                {
                 name: "portfolio/portfolio-details-1",
                 label: "Portfolio Details 01",
                },
                {
                 name: "portfolio/portfolio-details-2",
                 label: "Portfolio Details 02",
                },
                {
                 name: "portfolio/portfolio-details-3",
                 label: "Portfolio Details 03",
                },
               ]
             }, */
            //  col_6 : [
            //    {
            //     name: "supa",
            //     label: "supa",
            //    },
            //    {
            //     name: "Mega",
            //     label: "Mega",
            //    },
            //    {
            //     name: "menu",
            //     label: "Menu",
            //    },
            //    {
            //     name: "lele",
            //     label: "LEle",
            //    }
            //   ]
           //},
          },
         {
           label: "Recursos",
            items: [
              {
                name: "centroAyuda",
                label: "Centro de ayuda",
              },
              {
                name: "tutoriales",
                label: "Tutoriales",
              },
              {
                name: "contacto",
                label: "Centro de contacto",
              },
            ], 
         },
       ];
export default menuItems;