import React from "react";
import { Helmet } from "react-helmet";
import favicon_32 from '~image/favicon/favicon-32.png';
import favicon_57 from '~image/favicon/favicon-57.png';
import favicon_76 from '~image/favicon/favicon-76.png';
import favicon_96 from '~image/favicon/favicon-96.png';
import favicon_128 from '~image/favicon/favicon-128.png';
import favicon_192 from '~image/favicon/favicon-192.png';
import favicon_228 from '~image/favicon/favicon-228.png';
import favicon_196 from '~image/favicon/favicon-196.png';
import favicon_152 from '~image/favicon/favicon-152.png';
import favicon_180 from '~image/favicon/favicon-180.png';

import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
    <Helmet>
          <title>MiEmpeño - Partners</title>
          <link rel="icon" href={favicon_32} sizes="32x32"/>
          <link rel="icon" href={favicon_57} sizes="57x57"/>
          <link rel="icon" href={favicon_76} sizes="76x76"/>
          <link rel="icon" href={favicon_96} sizes="96x96"/>
          <link rel="icon" href={favicon_128} sizes="128x128"/>
          <link rel="icon" href={favicon_192} sizes="192x192"/>
          <link rel="icon" href={favicon_228} sizes="228x228"/>
          <link rel="icon" href={favicon_196} sizes="196x196"/>
          <link rel="apple-touch-icon" href={favicon_152} sizes="152x152"/>
          <link rel="apple-touch-icon" href={favicon_180} sizes="180x180"/>
    </Helmet>
      <Header/>
      {children}
    </>
  )
}
